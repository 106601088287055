import {useContext, useState} from 'react';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import {ThemeContext} from 'styled-components';
import {API_PATCH_TOURISTIC_SITE} from '../../../utils/api';
import touristicSiteSchema from '../../_schemas/touristic_site_schema';
import {TextArea, TextInput, Form} from '../../../_ui/_v2/components/FormElements';
import Button from '../../../_ui/_v2/components/Button';
import Loader from '../../../_ui/_v2/components/Loader';
import TouristicSiteContext from '../../../_contexts/TouristicSiteContext';

const touristicSiteSchemaForTouristicSiteMainInformationForm = touristicSiteSchema.pick([
  'title_fr',
  'title_en',
  'title_sp',
  'description_fr',
  'description_en',
  'description_sp',
  'phone_number',
  'website_url',
]);

const TouristicSiteMainInformationForm = ({onSuccess, touristicSite = {}}) => {
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const { setTouristicSite } = useContext(TouristicSiteContext);
  const { i18n } = useTranslation();
  const language = i18n.language;

  const titleKey = `title_${language}`;
  const descriptionKey = `description_${language}`;

  const handleSubmitForm = (values, actions) => {
    const token = localStorage.getItem("Authorization");

    setLoading(true);
    fetch(
      API_PATCH_TOURISTIC_SITE(touristicSite.id),
      {
        method: 'PATCH',
        cache: 'default',
        body: JSON.stringify(values),
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        mode: 'cors',
      }
    )
      .then(res => res.json())
      .then(response => {
        setTouristicSite(response);
        // setLoading(false);
        onSuccess?.(response);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: touristicSiteSchemaForTouristicSiteMainInformationForm.cast(touristicSite),
    validationSchema: touristicSiteSchemaForTouristicSiteMainInformationForm,
    onSubmit: handleSubmitForm,
  });

  return (
    <Form onSubmit={handleSubmit}>
      <TextInput
        type="text"
        name={titleKey}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[titleKey]}
        hasError={errors[titleKey] && touched[titleKey]}
        errorMessage={errors[titleKey]}
        placeholder={'Nom de l\'activité'}
      />
      <TextInput
        type="text"
        name={"phone_number"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values["phone_number"]}
        hasError={errors["phone_number"] && touched["phone_number"]}
        errorMessage={errors["phone_number"]}
        placeholder={'Numéro de téléphone'}
      />
      <TextInput
        type="text"
        name={"website_url"}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values["website_url"]}
        hasError={errors["website_url"] && touched["website_url"]}
        errorMessage={errors["website_url"]}
        placeholder={'Website lien'}
      />
      <TextArea
        name={descriptionKey}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[descriptionKey]}
        hasError={errors[descriptionKey] && touched[descriptionKey]}
        errorMessage={errors[descriptionKey]}
        placeholder={'Description'}
        rows="10"
      />
      <Button
        rounded
        disabled={loading}
        type="submit"
        style={{
          display: 'block',
          margin: '3em auto 0 auto',
        }}
      >
        {
          loading
            ? <Loader size="1.5em" color={theme.colors.white}/>
            : 'Enregistrer'
        }
      </Button>
    </Form>
  );
};

export default TouristicSiteMainInformationForm;